<template>
  <div class="sign-in">
    <v-row class="sign-in-wrapper">
      <v-col class="pa-0 sign-in-image-col" cols="12">
        <div class="sign-in-img">
          <div class="sign-in-cover"></div>
        </div>
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        {
          icon: "bubble_chart",
          title: "Inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Admin Panel",
    };
  },
};
</script>
  
  
  
  <style lang="scss" scoped>
#sso {
  display: none;
}
.sign-in {
  height: 100%;
  max-height: 100%;
  width: 100%;
  .sign-in-wrapper {
    height: 100%;
    width: 100%;
  }

  .sign-in-image-col {
    // -webkit-animation: spinX 3s infinite;
    overflow: hidden;
    position: relative;
    .sign-in-img {
      position: absolute;

      left: 0;
      bottom: 0;
      // top: -50vh;
      // left: -50vh;
      width: 100%;
      height: 100%;
      // background-image: url("/background-5.png");
      background-image: url("/background-1.png");
      background-size: cover;
      background-position: 10%, 50%;
      .sign-in-cover {
        height: 100%;
        max-height: 100%;
        width: 100%;
      }
    }

    @-webkit-keyframes spinX {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spinX {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
  