<template>
  <v-stepper
    v-model="step"
    alt-labels
    class="transparent elevation-0 main-stepper pa-0"
  >
    <v-stepper-header
      color="primary"
      dark
      class="content-card black stepper-header"
    >
      <v-stepper-step
        editable
        :complete="step > 1"
        step="1"
        :rules="[
          () =>
            $refs.pollSettingsForm && entered[1]
              ? $refs.pollSettingsForm.validate()
              : true,
        ]"
        @click="changeEntered([1])"
      >
        Poll Settings
      </v-stepper-step>

      <v-divider></v-divider>

      <v-divider></v-divider>

      <v-stepper-step
        editable
        step="2"
        :rules="[
          () =>
            $refs.pollBudgetForm && entered[2]
              ? $refs.pollBudgetForm.validate()
              : true,
        ]"
        @click="changeEntered([1, 2])"
      >
        Duration
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="stepper-items content-card white-blur">
      <v-stepper-content step="1">
        <PollForm ref="pollSettingsForm" v-model="value"></PollForm>

        <v-btn color="primary" @click="nextStep"> Continue </v-btn>

        <!-- <v-btn text> Cancel </v-btn> -->
      </v-stepper-content>

      <v-stepper-content step="2">
        <PollBudgetForm
          hide-budget
          ref="pollBudgetForm"
          v-model="value"
        ></PollBudgetForm>

        <v-btn v-if="step < 2" color="primary" @click="nextStep">
          Continue
        </v-btn>
        <v-btn
          v-else
          color="primary"
          @click="() => $emit('onCreate')"
          :loading="api.loading"
          :disabled="!this.validate() || api.loading"
        >
          Create Poll
        </v-btn>

        <!-- <v-btn text> Cancel </v-btn> -->
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { PollAPIInstance } from "../../../api";
import PollBudgetForm from "./PollBudgetForm.vue";
import PollForm from "./PollForm.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api:PollAPIInstance,

      
      step: 1,
      newPoll: {
        poll: {},
      },

      entered: {
        1: true,
        2: false,
      },
    };
  },
  components: {
    PollForm,
    PollBudgetForm,
  },
  methods: {
    changeEntered(steps) {
      // for (const step of steps) {
      //   this.entered[step] = false;
      // }
      setTimeout(() => {
        for (const step of steps) {
          this.entered[step] = true;
        }
      }, 400);
    },
    validate() {
      return (
        this.$refs.pollBudgetForm &&
        this.$refs.pollBudgetForm.validate() &&
        this.$refs.pollSettingsForm &&
        this.$refs.pollSettingsForm.validate()
      );
    },
    nextStep() {
      if (this.step < 2) {
        this.step++;
        this.entered[this.step] = true;

        console.log("VALUE: ", this.value);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.main-stepper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  .stepper-header {
    border-radius: 34px !important;
    margin-bottom: 30px !important;
  }
}
</style>