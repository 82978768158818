<template>
  <v-navigation-drawer
    app
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
    
    v-model="drawer"
    class="application-nav bg-black"
    
  >
    <v-img
      :src="'/logo.png'"
      :lazy-src="'/logo.png'"
      :size="80"
      class="logo"
      contain
    ></v-img>

    <!-- <v-list class="first-list">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>Pollyspot</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </v-list> -->

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="redirectTo(item.url)"
      >
        <v-list-item-icon>
          <v-icon :color="isActive(item) ? 'primary' : 'white'">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-4">
        <v-btn block @click="() => $router.push('/sign-in')"> Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>


<script>
export default {
  data() {
    return {
      drawer: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          url: "/dashboard",
          check: /dashboard/gi,
        },
        {
          title: "My Polls",
          icon: "mdi-poll",
          url: "/my-polls",
          check: /poll/gi,
        },
        {
          title: "My Influencers",
          icon: "mdi-heart",
          url: "/my-influencers",
          check: /influencer/gi,
        },
        {
          title: "My Spent",
          icon: "mdi-currency-usd",
          url: "/my-spent",
          check: /spent/gi,
        },
      ],
      rail: true,
      mini: false,
    };
  },
  methods: {
    redirectTo(url) {
      this.$router.push(url);
    },
    isActive(item) {
      return item && false;
      // return String(window.location.href).match(item.check);
    },
  },
};
</script>


<style lang="scss" scoped>
.application-nav {
  // margin-top: 64px;

  @media (max-width: 864px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 40px;
  }

  .first-list {
  }
  max-width: 300px;
}

.logo {
  height: 80px;
  margin-top: 30px;
}
</style>