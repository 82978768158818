<template>
  <div
    class="preview-wrapper-dialog"
    :style="{
      '--bgImage': `url(${
        publicPoll.style.bg
          ? getFileUrl(publicPoll.style.bg.file)
          : '/background-1.png'
      })`,
      'background-position': `${x}px ${y}px`,
    }"
    @mousemove="mouseMove"
  >
    <SimplePollPreview
      :value="publicPoll"
      submit
      @onSubmit="onSubmit"
    ></SimplePollPreview>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import SimplePollPreview from "../components/wad/organisms/poll/SimplePollPreview.vue";

export default {
  data() {
    return {
      short_token: "",

      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState("PollStore", ["publicPoll", "publicToken"]),
  },
  components: {
    SimplePollPreview,
  },
  async created() {
    this.short_token = this.$route.query.token;

    this.$store.dispatch("PollStore/getPublicPoll", {
      short_token: this.short_token,
    });

    // $("#top-image").mousemove(function (e) {

    //   //   $("#top-image").css(
    //   //     "background-position",
    //   //     newvalueX + "px     " + newvalueY + "px"
    //   //   );
    // });
  },
  methods: {
    onSubmit(answers) {
      this.$store.dispatch("PollStore/saveResult", {
        answers,
        token: this.publicToken,
      });
    },
    mouseMove(e) {
      //   setTimeout(() => {
      var movementStrength = 50;
      var height = movementStrength / window.screen.height;
      var width = movementStrength / window.screen.width;
      var pageX = e.pageX - window.screen.width / 2;
      var pageY = e.pageY - window.screen.height / 2;
      var newvalueX = width * pageX * -1 - 25;
      var newvalueY = height * pageY * -1 - 50;

      this.x = newvalueX;
      this.y = newvalueY;
      //   }, 100);
    },
  },
};
</script>


<style lang="scss" scoped>
.preview-wrapper-dialog {
  width: 100%;
  height: 100%;
  background: var(--bgImage);
  background-size: 120% 120%;
}
</style>