<template>
  <div
    :id="id"
    class="pie-calendar"
    :style="{
      height: height + 'px',
    }"
  ></div>
</template>
    
    
    
<script>
import * as echarts from "echarts";
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "pie-calendar",
    },
    valid: {
      type: Boolean,
      default: false,
    },

    start: {
      type: String,
      default: moment().subtract("14", "days").format("YYYY-MM-DD"),
    },
    end: {
      type: String,
      default: moment().format("YYYY-MM-DD"),
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      colors: ["#FF0087", "#FFBF00", "#80FFA5", "#00DDFF", "#37A2FF"],
      colorsGradient: ["#87009d", "#e03e4c", "#01bfec", "#4d77ff", "#7415db"],
    };
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = []) {
      let cellSize = [60, 60];
      let pieRadius = 20;

      console.log("window.innerWidth: ", window.innerWidth);

      if (window.innerWidth <= 1700 && window.innerWidth >= 1400) {
        cellSize = [50, 50];
      } else if (window.innerWidth < 1400 && window.innerWidth >= 800) {
        cellSize = [40, 40];
      } else if (window.innerWidth < 800) {
        cellSize = [38, 38];
        pieRadius = 14;
      }

      const pieSeries = Object.keys(data).map((key, index) => {
        return {
          type: "pie",
          id: "pie-" + index,
          center: key,
          radius: pieRadius,
          coordinateSystem: "calendar",
          label: {
            show: false,
            formatter: "{c}",
            position: "inside",
          },
          itemStyle: {
            opacity: 0.6,
            color: this.getColor.bind(this),
          },
          data: data[key].map((el) => ({
            name: el.answer || undefined,
            value: el.count || undefined,
          })),
        };
      });

      return {
        tooltip: {},
        color: ["#FF0087", "#80FFA5", "#00DDFF", "#37A2FF", "#FFBF00"],

        legend: {
          show: false,
          data: ["Work", "Entertainment", "Sleep"],
          bottom: 20,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        calendar: {
          top: "middle",
          left: "center",
          orient: "vertical",
          cellSize: cellSize,
          itemStyle: {
            borderColor: "#879BC3",
            color: "transparent",
          },
          splitLine: {
            lineStyle: {
              color: "#879BC3",
              join: "round",
              cap: "round",
            },
          },
          yearLabel: {
            show: false,
            fontFamily: "Dongle",
            fontSize: 20,
          },
          dayLabel: {
            margin: 10,
            firstDay: 1,
            nameMap: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            fontFamily: "Dongle",
            fontSize: 20,
          },
          monthLabel: {
            show: true,
            fontFamily: "Dongle",
          },
          range: [
            // '2023-02-01',

            moment(this.end).diff(moment(this.start), "days") < 14
              ? moment(this.end).subtract("7", "days").format("YYYY-MM-DD")
              : moment(this.start).format("YYYY-MM-DD"),

            moment(this.end).diff(moment(this.start), "days") < 14
              ? moment(this.end).add("7", "days").format("YYYY-MM-DD")
              : moment(this.end).format("YYYY-MM-DD"),
          ],
        },
        visualMap: {
          min: 0,
          max: 1,
          show: false,
          inRange: {
            color: ["#4288FF"],
            opacity: [0, 0.3],
          },
          controller: {
            inRange: {
              opacity: [0.3, 0.6],
            },
            outOfRange: {
              color: "#ccc",
            },
          },
          seriesIndex: [1],
          orient: "horizontal",
          left: "10%",
          bottom: 20,
        },
        series: [
          {
            id: "label",
            type: "scatter",
            coordinateSystem: "calendar",
            symbolSize: 0,
            label: {
              show: true,
              color: "#112D9A",
              formatter: function (params) {
                return echarts.time.format(params.value[0], "{dd}", false);
              },
              offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
              fontSize: 16,
              fontFamily: "Dongle",
            },
            data: Object.keys(data).map((el) => [el]),
          },
          {
            type: "heatmap",
            coordinateSystem: "calendar",
            data: new Array(
              moment(this.end).diff(moment(this.start), "days") + 1
            )
              .fill("-")
              .map((el, i) => [
                moment(this.start).add(i, "days").format("YYYY-MM-DD"),
                1,
              ]),
            label: {
              show: false,
            },
            silent: true,
          },
          ...pieSeries,
        ],
      };
    },
    getColor(color) {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: this.colors[color.dataIndex],
        },
        {
          offset: 1,
          color: this.colorsGradient[color.dataIndex],
        },
      ]);
    },
  },

  watch: {
    value: {
      handler(newVal) {
        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.pie-calendar {
  height: 100%;
  width: 100% !important;
}
</style>