<template>
  <v-row class="d-flex justify-center preview-row">
    <v-col class="preview-col">
      <v-card class="poll-container">
        <v-card-title
          class="poll-title bg-primary-gradient"
          :style="{
            '--bgImage': `url(${
              value.style.header
                ? getFileUrl(value.style.header.file)
                : '/background-1.png'
            })`,
            fontSize: (value.style.header_font_size || 26) + 'px',
            color: value.style.header_font_color,
          }"
        >
          {{ value.name }}

          <v-img
            v-if="value.style.logo"
            @click="openLink"
            contain
            class="logo"
            :src="getFileUrl(value.style.logo.file)"
            :lazy-src="getFileUrl(value.style.logo.file)"
            :style="{
              'border-radius': value.style.logo_border_radius + 'px',
              'border-color': value.style.logo_border_color,
              'border-width': value.style.logo_border_width + 'px',
              'border-style': 'solid',
              width: value.style.logo_size + 'px',
              height: value.style.logo_size + 'px',
            }"
          ></v-img>
        </v-card-title>

        <v-card-subtitle
          class="poll-question"
          :style="{
            fontSize: (value.style.question_font_size || 22) + 'px',
          }"
        >
          {{ value.question }}

          <br />
          <PreviewCarousel
            v-if="[...(value.files || []), ...value.attachments].length"
            :value="[...(value.files || []), ...value.attachments]"
            height="30vh"
          ></PreviewCarousel>
        </v-card-subtitle>

        <v-card-text class="poll-content mb-0 pb-0">
          <v-alert
            class="poll-alert"
            dense
            type="info"
            v-if="value.settings && value.settings.allow_multiple_answers"
          >
            Multiple answers <strong>allowed</strong>
          </v-alert>

          <TransitionGroup name="list-a" class="answers-container" tag="div">
            <v-list-item
              class="answer-item mt-2 mb-2 secondary"
              v-for="answer in value.answers"
              :key="answer.text"
            >
              <!-- @click="answer.selected = !answer.selected" -->
              <v-list-item-action>
                <v-checkbox
                  v-model="answer.selected"
                  :color="(answer.selected && 'success') || 'primary'"
                >
                  <template v-slot:label>
                    <div
                      :class="
                        (answer.selected && 'success--text') || 'primary--text'
                      "
                      class="ml-4"
                      v-text="answer.text"
                    ></div>
                  </template>
                </v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </TransitionGroup>
        </v-card-text>

        <v-card-actions v-if="submit" class="mb-8 submit-actions">
          <v-btn
            rounded
            dark
            class="bubbly-button"
            @click="onSubmit"
            :disabled="!selectedAnswers.length"
            :class="{
              animate,
            }"
          >
            Save My Answers
          </v-btn>
        </v-card-actions>

        <v-card-actions class="card-actions-bottom terms-and-actions">
          <p class="terms-p">
            Please make sure that you agree with
            <a target="blank" href="https://pollyspot.com/terms-and-conditions.html">Polly Spot Terms</a> and
            <a target="blank" href=""> {{ value.brand || "Provider" }} Terms</a>
          </p>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import PreviewCarousel from "../../molecules/poll/preview/PreviewCarousel.vue";

export default {
  props: {
    value: {},
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      animate: false,
    };
  },
  components: {
    PreviewCarousel,
  },
  computed: {
    selectedAnswers: function () {
      return this.value.answers
        ? this.value.answers.filter((a) => a.selected)
        : [];
    },
  },
  methods: {
    openLink() {
      window.open(
        (this.value.customer && this.value.customer.url) ||
          "https://polly-spot.com",
        "_blank"
      );
    },
    onSubmit() {
      this.animate = true;

      this.$emit("onSubmit", this.selectedAnswers);
      setTimeout(() => {
        this.animate = false;
      }, 700);
    },
    getFileUrl(file) {
      if (file.id && file.url) return file.url;
      else return URL.createObjectURL(file);
    },
  },
};
</script>


<style lang="scss" scoped>
.preview-row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  .preview-col {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .poll-container {
      width: 100%;
      max-width: 600px;
      align-items: center;
      border: 2px solid rgba($color: #ffffff, $alpha: 0.4) !important;
      background-color: rgba($color: #ffffff, $alpha: 0.6) !important;

      .poll-title {
        color: white;
        min-height: 80px;
        padding-top: 30px;
        background: var(--bgImage);
        background-size: cover;
        overflow: hidden;

        .logo {
          position: absolute;
          left: 0px;
          transform: rotate(15deg);
          top: -20px;
          width: 80px;
          cursor: pointer;
        }
      }

      .poll-question {
        border-radius: 8px;
        margin-left: 4px;
        margin-right: 4px;
        padding: 8px;
        background-color: rgba($color: #ffffff, $alpha: 0.9) !important;
      }

      .poll-content {
        overflow-y: auto;
        max-height: 300px;

        .poll-alert {
          margin-top: 12px;
          margin-bottom: 12px;
          font-size: 18px;
          line-height: 20px;
        }
        .answers-container {
          .answer-item {
            border: 1px solid rgba($color: #000000, $alpha: 0.8);
            border-radius: 20px;
            font-size: 20px;
            &:hover::before {
              border-radius: 6px;
              border: 1px solid rgba($color: #000000, $alpha: 0.8);
            }
          }
          margin-bottom: 40px;
        }
      }

      .submit-actions {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .submit-btn {
          width: 60% !important;
          text-transform: none;
          letter-spacing: 3px;
          font-size: 1.2rem;
          font-weight: 400;
        }
      }

      .terms-and-actions {
        background-color: rgba($color: #000000, $alpha: 0.5);
        .terms-p {
          color: white;
          font-size: 16px;
          margin: 0px;
          vertical-align: bottom;
          line-height: 16px;
        }
      }
    }
  }
}

//  BUTTON ANIMATION

$fuschia: #4288ff;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

// body{
//   font-size: 16px;
//   font-family: 'Helvetica', 'Arial', sans-serif;
//   text-align: center;
//   background-color: $baby-blue;
// }

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(124, 124, 124, 0.32) !important;
}
.bubbly-button {
  display: inline-block;
  z-index: 9;
  margin-bottom: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg !important;
  color: $button-text-color;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  // box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);

  padding: 4px;
  // width: 60% !important;
  height: 60px !important;
  width: 250px;
  // width: 50px;
  text-transform: none;
  letter-spacing: 3px;
  font-size: 1.2rem;
  font-weight: 400;

  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
    box-shadow: none;
    background-color: transparent;
    // background: transparent;
  }

  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
      15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }

  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
      20% 20%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  &:active {
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }

  &.animate {
    &:before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
</style>