<template>
  <div :id="id" class="fraud-score"></div>
</template>
    
    
    
<script>
import * as echarts from "echarts";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "fraud-score",
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = {}) {
      return {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "100%"],
            radius: "180%",
            min: 0,
            max: 1,
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, "#7CFFB2"],
                  [0.5, "#58D9F9"],
                  [0.75, "#FDDD60"],
                  [1, "#FF6E76"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-60%"],
              itemStyle: {
                color: "inherit",
              },
            },
            axisTick: {
              length: 8,
              lineStyle: {
                color: "white",
                width: 1,
              },
            },
            splitLine: {
              length: 16,
              lineStyle: {
                color: "white",
                width: 1,
              },
            },
            axisLabel: {
              color: "white",
              fontSize: 20,
              rotate: "tangential",
              formatter: function () {
                // if (value === 0.875) {
                //   return "Critical";
                // } else if (value === 0.625) {
                //   return "High";
                // } else if (value === 0.375) {
                //   return "Medium";
                // } else if (value === 0.125) {
                //   return "Low";
                // }
                return "";
              },
            },
            title: {
              offsetCenter: [0, "-10%"],
              fontSize: 14,
            },
            detail: {
              fontSize: 16,
              offsetCenter: [0, "-35%"],
              valueAnimation: true,
              formatter: function (value) {
                return isNaN(Math.round(value * 100))
                  ? "N/A"
                  : Math.round(value * 100) + "%";
              },
              color: "inherit",
            },
            data: [
              {
                value: data.score,
                name: "Fraud Score",
                title: {
                  color: "white",
                },
              },
            ],
          },
        ],
      };
    },
  },

  watch: {
    value: {
      handler(newVal) {

        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.fraud-score {
  height: 100%;
  width: 100%;
  height: 90px;
}
</style>