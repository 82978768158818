<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="8" md="6" sm="6">
        <v-tabs color="primary" light v-model="tab" icons-and-text background-color="transparent">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#1">
            General
            <v-icon>mdi-cog-outline</v-icon>
          </v-tab>

          <v-tab href="#2">
            Style
            <v-icon>mdi-palette-swatch-outline</v-icon>
          </v-tab>

          <v-tab href="#3">
            Answers
            <v-icon>mdi-bullhorn-variant-outline</v-icon>
          </v-tab>

          <v-tab href="#4">
            Attachments
            <v-icon>mdi-attachment</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparent">
          <!-- General -->
          <v-tab-item value="1" class="pa-4">
            <HelpFormInputVue
              v-model="form.name"
              :rules="nameRules"
              label="Poll Name"
              :helpText="'Please provide a poll name'"
              required
            ></HelpFormInputVue>

            <HelpFormInputVue
              :rows="4"
              v-model="form.question"
              :rules="questionRules"
              label="Poll Question"
              :helpText="'Please define a transparent goal of this poll to let users what you want to achieve. '"
              :textarea="true"
              required
            ></HelpFormInputVue>

            <HelpFormInputVue
              v-model="form.terms_url"
              :rules="termRules"
              label="Link to your terms"
              :helpText="'Please provide a link to your terms and conditions'"
              required
            ></HelpFormInputVue>
          </v-tab-item>

          <!-- Style -->

          <v-tab-item value="2" class="pa-4">
            <!-- <v-switch
              v-model="form.poll.allow_multiple_answers"
              :label="'Allow multiple answers'"
            ></v-switch> -->

            <v-color-picker
              dot-size="25"
              swatches-max-height="200"
            ></v-color-picker>

            <VueFileAgent
              ref="vueFileAgent"
              :theme="'list'"
              :multiple="false"
              :meta="true"
              :accept="'image/*'"
              :maxSize="'10MB'"
              :maxFiles="14"
              :helpText="'Chose Custom Background or skip'"
              :errorText="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10MB in size',
              }"
              v-model="form.style.bg"
            ></VueFileAgent>

            <VueFileAgent
              ref="vueFileAgent"
              :theme="'list'"
              :multiple="false"
              :meta="true"
              :accept="'image/*'"
              :maxSize="'10MB'"
              :maxFiles="14"
              :helpText="'Chose Custom Logo or skip'"
              :errorText="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10MB in size',
              }"
              v-model="form.style.logo"
            ></VueFileAgent>
          </v-tab-item>

          <!-- Answers -->
          <v-tab-item value="3" class="pa-4">
            <v-switch
              v-model="form.settings.allow_multiple_answers"
              :label="'Allow multiple answers'"
            ></v-switch>
            <HelpFormInputVue
              v-model="newAnswer"
              label="Add Answer"
              :helpText="'Here you can create any number of answers for your poll'"
              required
              @onEnter="create"
            ></HelpFormInputVue>
            <TransitionGroup name="list-a" class="answers-container" tag="div">
              <v-list-item
                link
                class="answer-item ma-2"
                v-for="(answer, i) in value.answers"
                :key="answer.text"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-circle-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="answer.text"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon color="error" @click="remove(i)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </TransitionGroup>
          </v-tab-item>

          <!--  Attachments -->

          <v-tab-item value="4" class="pa-4">
            <VueFileAgent
              ref="vueFileAgent"
              :theme="'list'"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/*,.zip, video/*'"
              :maxSize="'10MB'"
              :maxFiles="14"
              :helpText="'Choose images or zip files'"
              :errorText="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10MB in size',
              }"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
              v-model="form.files"
            ></VueFileAgent>

            <HelpFormInputVue
              v-model="newVideo"
              label="Video URL"
              :helpText="'Please provide url to your video. Youtube or Vimeo'"
              :rules="videoRules"
              required
              @onEnter="addVideo"
            ></HelpFormInputVue>

            <PreviewCarousel
              :value="[...form.files, ...form.attachments]"
            ></PreviewCarousel>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" lg="4" md="6" sm="6" class="preview-col">
        <MobilePollPreview :value="value"></MobilePollPreview>
      </v-col>
    </v-row>
   
  </v-form>
</template>
      
      
<script>
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import MobilePollPreview from '../../organisms/poll/MobilePollPreview.vue';
import PreviewCarousel from "./preview/PreviewCarousel.vue";


// src="https://www.youtube.com/embed/zLaGZBPOEmo"
//
export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,
      newAnswer: "",
      newVideo: "",
      tab: 1,

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      termRules: [(v) => !!v || "Link to your Terms & Conditions is required"],
      questionRules: [
        (v) => !!v || "The poll should provide a transparent question",
      ],
      videoRules: [
        (v) =>
          !!v && !v.match(/(youtube|vimeo|youtu\.be)/gi)
            ? "Sorry, but we can't recognize video provider. You can use attachments in this case."
            : true,
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {},
  components: {
    HelpFormInputVue,
    PreviewCarousel,
    MobilePollPreview
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    create() {
      if (!this.newAnswer) return;

      this.form.answers.push({
        text: this.newAnswer,
      });

      this.newAnswer = "";

      console.log("form/.files", this.form.style.bg);
    },
    remove(i) {
      this.form.answers.splice(i, 1);
    },

    addVideo() {
      if (!this.newVideo) return;

      //   https://www.youtube.com/watch?v=a5JvHJjpSKo
      //   https://www.youtube.com/embed/a5JvHJjpSKo
      //   https://youtu.be/a5JvHJjpSKo

      let properSrc = this.newVideo;
      let provider = "youtube";

      if (this.newVideo.match(/youtu\.be/gi)) {
        if (this.newVideo[this.newVideo.length - 1] === "/") {
          const prep = this.newVideo.slice(0, this.newVideo.length - 1);
          const [id] = prep.split("/").reverse();
          properSrc = ` https://www.youtube.com/embed/${id}`;
        } else {
          const [id] = this.newVideo.split("/").reverse();
          properSrc = ` https://www.youtube.com/embed/${id}`;
        }
      }

      if (
        this.newVideo.match(/youtube\.com/gi) &&
        this.newVideo.match(/watch/gi)
      ) {
        const [id] = this.newVideo.split("=").reverse();
        properSrc = ` https://www.youtube.com/embed/${id}`;
      }

      if (this.newVideo.match(/vimeo/gi)) provider = "vimeo";

      this.form.attachments.push({
        video_src: properSrc,
        videoProvider: provider,
      });

      this.newVideo = "";

      console.log("form/.files", this.form);
    },
    removeVideo(i) {
      this.form.attachments.splice(i, 1);
    },



    // SOME

    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.form.files
      );
      this.form.files = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    // filesSelected: function (fileRecordsNewlySelected) {
    //   var validFileRecords = fileRecordsNewlySelected.filter(
    //     (fileRecord) => !fileRecord.error
    //   );
    //   this.fileRecordsForUpload =
    //     this.fileRecordsForUpload.concat(validFileRecords);
    // },
    onBeforeDelete: function (fileRecord) {
      var i = this.form.files.indexOf(fileRecord);
      if (i !== -1) {
        this.form.files.splice(i, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.form.files.indexOf(fileRecord);
      if (i !== -1) {
        this.form.files.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
  <style lang="scss" scoped>
.answers-container {
  transition: all 0.5s ease;
  max-height: 300px;
  overflow-y: auto;
}
.answer-item {
  border-radius: 14px;
  border-color: #EBBF00;

  border-bottom: 1px solid;

  &:hover::before {
    border-radius: 14px !important;
  }
}

.preview-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
</style>