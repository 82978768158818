<template>
  <div
    :id="id"
    class="line-chart"
    :style="{
      'height': height + 'px',
    }"
  ></div>
</template>
  
  
  
  <script>
import * as echarts from "echarts";

export default {
  props: {
    id: {
      type: String,
      default: "pie-calendar",
    },
    value: {
      type: Array,
      default: () => [],
    },
    valid: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      colors: ["#FF0087", "#80FFA5", "#00DDFF", "#37A2FF", "#FFBF00"],
      colorsGradient: ["#87009d", "#01bfec", "#4d77ff", "#7415db", "#e03e4c"],
    };
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = []) {
      const days = data.map((el) => el.day);

      return {
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        title: {
          left: "center",
          text: "Large Area Chart",
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: days,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        dataZoom: [
          {
            type: "inside",
            start: 80,
            end: 100,
          },
          {
            start: 80,
            end: 100,
          },
        ],
        series: [
          {
            name: "Clicks",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "#4288FF",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(255, 158, 68)",
                },
                {
                  offset: 1,
                  color: "rgb(255, 70, 131)",
                },
              ]),
            },
            data: data.map((el) => el.count || 0),
          },
        ],
      };
    },
  },

  watch: {
    value: {
      handler(newVal) {
        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.line-chart {
  height: 100%;
  width: 100%;
}
</style>