<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <SelectorCountry
          autoload
          class="mt-4"
          multiple
          label="Location (Countries)"
          v-model="countries"
          :helpText="'You can specify countries that you want to include into your poll results'"
          required
          return-object
        ></SelectorCountry>

        <SelectorAgeCategories
          autoload
          multiple
          class="mt-4"
          label="Age Categories"
          v-model="ages"
          :helpText="'You can specify age categories that you want to include into your poll results'"
          required
          return-object
        ></SelectorAgeCategories>

        <SelectorGenderCategories
          autoload
          multiple
          class="mt-4"
          label="Gender Categories"
          v-model="genders"
          :helpText="'You can specify a target gender, but we suggesting to do it by providing a proper tags and interests.'"
          required
          return-object
        ></SelectorGenderCategories>

        <SelectorSourceCategories
          autoload
          multiple
          class="mt-4"
          label="Target Sources"
          v-model="sources"
          :helpText="'You can specify a set of sources from where you want to receive answers'"
          required
          return-object
        ></SelectorSourceCategories>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <SelectorParentInterestsCategories
          autoload
          multiple
          class="mt-4"
          label="Please Select Parent Categories"
          v-model="parentInterests"
          :helpText="'You can specify a set of sources from where you want to receive answers'"
          required
          :counter="3"
          :rules="parentRules"
          return-object
        ></SelectorParentInterestsCategories>

        <SelectorInterestsCategories
          v-for="parent in parentInterests"
          :key="parent.id"
          :parentId="parent.id"
          autoload
          multiple
          class="mt-4"
          :label="`Select Category Tags from  ${parent.name}`"
          v-model="interests"
          :helpText="'You can specify a set of sources from where you want to receive answers'"
          required
          :counter="3"
          :rules="interestRules"
          return-object
        ></SelectorInterestsCategories>
      </v-col>
    </v-row>
  </v-form>
</template>
        
        
  <script>
import SelectorAgeCategories from "../../atoms/common/autocompletes/SelectorAgeCategories.vue";
import SelectorCountry from "../../atoms/common/autocompletes/SelectorCountry.vue";
// import SelectorDefaultVue from "../../atoms/common/autocompletes/SelectorDefault.vue";
import SelectorGenderCategories from "../../atoms/common/autocompletes/SelectorGenderCategories.vue";
import SelectorInterestsCategories from "../../atoms/common/autocompletes/SelectorInterestsCategories.vue";
import SelectorParentInterestsCategories from "../../atoms/common/autocompletes/SelectorParentInterestsCategories.vue";
import SelectorSourceCategories from "../../atoms/common/autocompletes/SelectorSourceCategories.vue";

// import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

// src="https://www.youtube.com/embed/zLaGZBPOEmo"
//
export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,
      newAnswer: "",
      newVideo: "",

      ages: [],
      genders: [],
      sources: [],
      countries: [],
      parentInterests: [],
      interests: [],

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      parentRules: [
        (v) => (v && v.length ? true : "At least one category is required"),
        (v) => v.length <= 3 || "You can select maximum 3 category at once",
      ],

      interestRules: [
        (v) => (v && v.length ? true : "At least one tag is required"),
        (v) => v.length <= 10 || "You can select maximum 20 category tags.",
      ],
    };
  },
  computed: {},
  components: {
    // HelpFormInputVue,
    SelectorAgeCategories,
    // SelectorDefaultVue,
    SelectorGenderCategories,
    SelectorSourceCategories,
    SelectorParentInterestsCategories,
    SelectorCountry,
    SelectorInterestsCategories,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    ages: {
      handler(newVal) {
        this.form.audience.ages = newVal.map(category=>({category}));
      },
      deep: true,
    },
    sources: {
      handler(newVal) {
        this.form.audience.sources = newVal.map(category=>({category}));
      },
      deep: true,
    },
    genders: {
      handler(newVal) {
        this.form.audience.genders = newVal.map(category=>({category}));
      },
      deep: true,
    },
    interests: {
      handler(newVal) {
        this.form.audience.interests = newVal.map(category=>({category}));
      },
      deep: true,
    },
    countries: {
      handler(newVal) {
        this.form.audience.countries = newVal.map(category=>({category}));
      },
      deep: true,
    },
    
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
    <style lang="scss" scoped>
.answers-container {
  transition: all 0.5s ease;
}
.answer-item {
  border-radius: 14px;
  border-color: #EBBF00;

  border-bottom: 1px solid;

  &:hover::before {
    border-radius: 14px !important;
  }
}
</style>