var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"d-flex justify-center preview-row"},[_c('v-col',{staticClass:"preview-col"},[_c('v-card',{staticClass:"poll-container"},[_c('v-card-title',{staticClass:"poll-title bg-primary-gradient",style:({
          '--bgImage': `url(${
            _vm.value.style.header
              ? _vm.getFileUrl(_vm.value.style.header.file)
              : '/background-1.png'
          })`,
          fontSize: (_vm.value.style.header_font_size || 26) + 'px',
          color: _vm.value.style.header_font_color,
        })},[_vm._v(" "+_vm._s(_vm.value.name)+" "),(_vm.value.style.logo)?_c('v-img',{staticClass:"logo",style:({
            'border-radius': _vm.value.style.logo_border_radius + 'px',
            'border-color': _vm.value.style.logo_border_color,
            'border-width': _vm.value.style.logo_border_width + 'px',
            'border-style': 'solid',
            width: _vm.value.style.logo_size + 'px',
            height: _vm.value.style.logo_size + 'px',
          }),attrs:{"contain":"","src":_vm.getFileUrl(_vm.value.style.logo.file),"lazy-src":_vm.getFileUrl(_vm.value.style.logo.file)},on:{"click":_vm.openLink}}):_vm._e()],1),_c('v-card-subtitle',{staticClass:"poll-question",style:({
          fontSize: (_vm.value.style.question_font_size || 22) + 'px',
        })},[_vm._v(" "+_vm._s(_vm.value.question)+" "),_c('br'),([...(_vm.value.files || []), ..._vm.value.attachments].length)?_c('PreviewCarousel',{attrs:{"value":[...(_vm.value.files || []), ..._vm.value.attachments],"height":"30vh"}}):_vm._e()],1),_c('v-card-text',{staticClass:"poll-content mb-0 pb-0"},[(_vm.value.settings && _vm.value.settings.allow_multiple_answers)?_c('v-alert',{staticClass:"poll-alert",attrs:{"dense":"","type":"info"}},[_vm._v(" Multiple answers "),_c('strong',[_vm._v("allowed")])]):_vm._e(),_c('TransitionGroup',{staticClass:"answers-container",attrs:{"name":"list-a","tag":"div"}},_vm._l((_vm.value.answers),function(answer){return _c('v-list-item',{key:answer.text,staticClass:"answer-item mt-2 mb-2 secondary"},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":(answer.selected && 'success') || 'primary'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"ml-4",class:(answer.selected && 'success--text') || 'primary--text',domProps:{"textContent":_vm._s(answer.text)}})]},proxy:true}],null,true),model:{value:(answer.selected),callback:function ($$v) {_vm.$set(answer, "selected", $$v)},expression:"answer.selected"}})],1)],1)}),1)],1),(_vm.submit)?_c('v-card-actions',{staticClass:"mb-8 submit-actions"},[_c('v-btn',{staticClass:"bubbly-button",class:{
            animate: _vm.animate,
          },attrs:{"rounded":"","dark":"","disabled":!_vm.selectedAnswers.length},on:{"click":_vm.onSubmit}},[_vm._v(" Save My Answers ")])],1):_vm._e(),_c('v-card-actions',{staticClass:"card-actions-bottom terms-and-actions"},[_c('p',{staticClass:"terms-p"},[_vm._v(" Please make sure that you agree with "),_c('a',{attrs:{"target":"blank","href":"https://pollyspot.com/terms-and-conditions.html"}},[_vm._v("Polly Spot Terms")]),_vm._v(" and "),_c('a',{attrs:{"target":"blank","href":""}},[_vm._v(" "+_vm._s(_vm.value.brand || "Provider")+" Terms")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }