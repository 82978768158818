<template>
  <div
    :id="id"
    class="poll-results"
    :style="{
      'height': height + 'px',
    }"
  ></div>
</template>
    
    
    
<script>
import * as echarts from "echarts";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "poll-results",
    },
    valid: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {};
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = {}) {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          show: false,
        },
        visualMap: {
          show: false, // Map the score column to color
          dimension: 0,
          min: data[Object.keys(data)[0]],
          max: data[Object.keys(data)[Object.keys(data).length - 1]],
          inRange: {
            color: ["#D5DDEE", "#4288ff", "#112D9A"],
          },
        },
        yAxis: {
          type: "category",
          interval: 0,
          data: Object.keys(data),
          nameTextStyle: {
            fontFamily: "Dongle",
          },
          axisLabel: {
            fontFamily: "Dongle",
            fontSize: 20,
          },
          inverse: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },

        series: [
          {
            name: "answers total",
            type: "bar",
            itemStyle: {
              borderRadius: 20,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
            data: Object.values(data),
          },
        ],
      };
    },
  },

  watch: {
    value: {
      handler(newVal) {
        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.poll-results {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
</style>