<template>
  <v-card class="elevation-0 page-card">
    <v-card-title class="page-card-title">
      <v-btn icon @click="$router.push('/my-polls')"
        ><v-icon color="primary" class="mr-2">mdi-arrow-left</v-icon></v-btn
      >

      <h2 class="page-title">New Poll</h2>
    </v-card-title>
    <v-card-text>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0">
          <SimplePollCreationStepper
            v-if="newPoll.type.code === 'simple'"
            v-model="newPoll"
            @onCreate="create"
            ref="form"
          ></SimplePollCreationStepper>

          <CampaignPollCreationStepper
            v-else
            v-model="newPoll"
            @onCreate="create"
            ref="form"
          ></CampaignPollCreationStepper>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import SimplePollCreationStepper from "../components/wad/molecules/poll/SimplePollCreationStepper.vue";

import moment from "moment";
import { DEFAULT_POLL_TYPES } from "../constants/poll.constants";
import CampaignPollCreationStepper from "../components/wad/molecules/poll/CampaignPollCreationStepper.vue";

export default {
  data() {
    return {
      newPoll: {
        name: "",
        question: "",
        terms_url: "https://pollyspot.com/terms-and-conditions.html",
        type: {},
        style: {
          header_font_color: "",
          header_font_size: 24,
          header_bg_color_1: "",
          header_bg_color_2: "",
          logo_width: 0,
          logo_border_radius: 0,
          question_bg_color: "",
          question_font_color: "",
          question_font_size: 0,
          answer_bg_color: "",
          answer_font_color: "",
          answer_font_size: 0,
          answer_selected_color: "",
          answer_unselected_color: "",
          attachment_display: "separated",
          logo: undefined,
          bg: undefined,
        },
        settings: {
          allow_multiple_answers: false,
          block_display_before_start: false,
        },
        audience: {
          ages: [],
          genders: [],
          interests: [],
          sources: [],
          countries: [],
        },
        campaign: {
          budget: 100,
          goal: 100,
          dates: [
            moment().format("YYYY-MM-DD"),
            moment().add(2, "days").format("YYYY-MM-DD"),
          ],
        },
        attachments: [],
        answers: [],
        files: [],
        logo: undefined,
        bg: undefined,
      },
    };
  },
  components: {
    SimplePollCreationStepper,
    CampaignPollCreationStepper,
  },
  created() {
    if (!this.$route.params.typeId) this.$router.push("/my-polls");
    else
      this.newPoll.type = DEFAULT_POLL_TYPES.find(
        (t) => String(t.id) === String(this.$route.params.typeId)
      );
  },
  methods: {
    async create() {
      this.$store.dispatch("PollStore/create", this.newPoll);
      this.$router.push("/my-polls");
    },
  },
};
</script>