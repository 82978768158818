<template>
  <div
    :id="id"
    class="pie-chart"
    :style="{
      height: height + 'px',
    }"
  ></div>
</template>
  
  
  
  <script>
import * as echarts from "echarts";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "pie-chart",
    },
    valid: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 329,
    },
  },
  data() {
    return {
      colors: ["#00DDFF", "#37A2FF", "#FFBF00", "#FF0087", "#80FFA5"],
      colorsGradient: ["#4d77ff", "#7415db", "#e03e4c", "#87009d", "#01bfec"],
    };
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = {}) {
      return {
        color: this.colors,

        tooltip: {
          trigger: "item",
        },
        legend: {
          // top: "5%",
          // left: "center",
          show: false,
          bottom: 0,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "80%"],
            center: ["50%", "50%"],
            top: 0,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              opacity: 0.6,
              color: this.getColor.bind(this),
            },
            labelLine: {
              show: false,
            },
            data: Object.keys(data).length
              ? Object.keys(data).map((key) => ({
                  value: data[key],
                  name: key,
                }))
              : [],
          },
        ],
      };
    },
    getColor(color) {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: this.colors[color.dataIndex],
        },
        {
          offset: 1,
          color: this.colorsGradient[color.dataIndex],
        },
      ]);
    },
  },

  watch: {
    value: {
      handler(newVal) {
        console.log("NEVAL??? ", newVal);

        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.pie-chart {
  height: 100%;
  width: 100%;
}
</style>