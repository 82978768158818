
<template>
    <v-card class="elevation-0 transparent page-card">
      <v-card-title class="page-card-title">
      <!-- <v-btn
        icon
        class="mr-2"
        color="primary"
        @click="() => (newPollDialog = !newPollDialog)"
        ><v-icon>mdi-plus</v-icon>
      </v-btn> -->
      <h2 class="page-title">Dashboard</h2>
    </v-card-title>


    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="6" lg="6" md="6">
          <v-card>
            <PerDayChart></PerDayChart>
          </v-card>
        </v-col>

        <v-col cols="6" lg="6" md="6">
          <v-card>
            <LineChartVue></LineChartVue>
          </v-card>
        </v-col>
        <v-col cols="4" lg="6" md="6">
          <v-card>
            <PieChartVue></PieChartVue>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import LineChartVue from '../components/wad/molecules/dashboard/LineChart.vue';
import PerDayChart from "../components/wad/molecules/dashboard/PerDayChart.vue";
import PieChartVue from '../components/wad/molecules/dashboard/PieChart.vue';


export default {
  data() {
    return {};
  },
  components: {
    PerDayChart,
    LineChartVue,
    PieChartVue
  },
};
</script>