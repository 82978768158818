import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import SignInTemplate from '../views/SignInTemplate.vue'
import Home from '../views/Home.vue'
import MyPolls from '../views/MyPolls.vue'
import PollDetails from '../views/PollDetails.vue'
import NewPoll from '../views/NewPoll.vue'
import Dashboard from '../views/Dashboard.vue'
import GoogleCallback from '../views/GoogleCallback.vue'
import PublicPoll from '../views/PublicPoll.vue'
import MyInfluencers from '../views/MyInfluencers.vue'
import MySpent from '../views/MySpent.vue'

import {
  AuthAPIInstance
} from '../components/api'
import {
  store
} from '../main'



Vue.use(VueRouter)

const routes = [{
    path: '/sign-in',
    name: 'SignIn',
    component: SignInTemplate,
    children: [{
        path: '/',
        name: 'SignIn',
        component: SignIn,
      },
      {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
      }
    ]
  },
  {
    path: '/callback/google',
    name: 'Callback',
    component: SignInTemplate,
    children: [{
      path: '/',
      name: 'GoogleCallback',
      component: GoogleCallback,
    }]
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    },
    children: [
      // Dashboard
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          auth: true
        },
      },
      // Polls
      {
        path: '/my-polls',
        name: 'MyPolls',
        component: MyPolls,
        meta: {
          auth: true
        },
      },
      {
        path: '/my-polls/:id',
        name: 'PollDetails',
        component: PollDetails,
        meta: {
          auth: true
        },
      },
      {
        path: '/new-poll/:typeId',
        name: 'NewPoll',
        component: NewPoll,
        meta: {
          auth: true
        },
      },

      // Influencers
      {
        path: '/my-influencers',
        name: 'MyInfluencers',
        component: MyInfluencers,
        meta: {
          auth: true
        },
      },

      // Spent

      {
        path: '/my-spent',
        name: 'MySpent',
        component: MySpent,
        meta: {
          auth: true
        },
      },



    ]
  },
  {
    path: '/public/poll',
    name: 'PublicPoll',
    component: PublicPoll,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router
  .beforeEach(async (to, from, next) => {
    if (to.meta.auth) {
      const [token, user] = await Promise.all([
        store.dispatch("MainStore/getToken"),
        store.dispatch("MainStore/getUser"),
      ]);

      if (!token || !user) return next({
        path: '/sign-in',
        replace: true
      });

      try {
        await AuthAPIInstance.validateToken(token);
        console.log("TOKEN VALID");

        return next();

      } catch (error) {
        console.log("ERROR", error);
        store.dispatch("MainStore/setAuth", {});
        return next({
          path: '/sign-in',
          replace: true
        });

      }

    }

    return next();
  });

export default router