<template>
  <div
    :id="id"
    class="answer-by-source"
    :style="{
      height: height + 'px',
    }"
  ></div>
</template>
    
    
    
    <script>
import * as echarts from "echarts";
import _ from "lodash";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "answer-by-source",
    },
    valid: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      colors: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
      colorsGradient: ["#01bfec", "#4d77ff", "#7415db", "#87009d", "#e03e4c"],
    };
  },
  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = {}) {
      data;

      console.log(
        _.uniq(
          Object.values(data).reduce(
            (acc, curr) => [...acc, ...curr.map((el) => el.name)],
            []
          )
        )
      );
      return {
        color: this.colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          textStyle: {
            fontFamily: "Dongle",
            fontSize: 20,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: _.uniq(
            Object.values(data).reduce(
              (acc, curr) => [...acc, ...curr.map((el) => el.name)],
              []
            )
          ),

          nameTextStyle: {
            fontFamily: "Dongle",
          },
          axisLabel: {
            fontFamily: "Dongle",
            fontSize: 20,
          },
          inverse: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          ...Object.keys(data).map((key, i, arr) => ({
            name: key,
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: data[key].map((el) => el.value),
            itemStyle: {
              opacity: 0.7,
              color: () =>
                this.getColor({
                  dataIndex: i,
                }),
              borderRadius:
                arr.length === 1
                  ? [20, 20, 20, 20]
                  : i === 0
                  ? [20, 0, 0, 20]
                  : i === arr.length - 1
                  ? [0, 20, 20, 0]
                  : undefined,
            },
          })),
        ],
      };
    },
    getColor(color) {
      return new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        {
          offset: 0,
          color: this.colors[color.dataIndex],
        },
        {
          offset: 1,
          color: "rgb(1, 191, 236)",
        },
      ]);
    },
  },

  watch: {
    value: {
      handler(newVal) {
        console.log("NEVAL??? ", newVal);

        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.answer-by-source {
  height: 100%;
  width: 100%;
}
</style>