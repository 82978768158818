import {
    CommonAPI
} from "./common/Common.api";
import {
    UserAPI
} from "./users/User.api";
import {
    AuthAPI
} from "./Auth.api";
import {
    PollAPI
} from "./poll/Poll.api";
import { AudienceAPI } from "./Audience.api";





export const UserAPIInstance = new UserAPI(process.env.VUE_APP_API)
export const CommonAPIInstance = new CommonAPI(process.env.VUE_APP_API)
export const AuthAPIInstance = new AuthAPI(process.env.VUE_APP_API)
export const PollAPIInstance = new PollAPI(process.env.VUE_APP_API)
export const AudienceAPIInstance = new AudienceAPI(process.env.VUE_APP_API)

