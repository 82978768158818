import moment from 'moment';

export const DEFAULT_POLL_TYPES = [{
        id: 1,
        icon: "mdi-adjust",
        code: 'simple',
        src: "/icons/1.png",
        color: "primary",
        name: "Simple",
        short_code: 'S',
        description: `The "simple" poll type allows users to create their own polls and obtain close to real-time analytics through their personal resources.<br><br>
Users can distribute the polls through various channels and access insights into the results, making it a convenient and efficient solution for gathering feedback and making data-driven decisions.`,
        comingSoon: false,
    },
    {
        id: 2,
        icon: "mdi-slot-machine-outline",
        code: 'random_win',
        src: "/icons/2.png",
        color: "red",
        name: "Random Win",
        short_code: 'RW',
        description: `
          With the "random win" poll type, users can create engaging polls and surveys that offer respondents the chance to win prizes. By defining a target audience and the number of prizes to be awarded, poll creators can incentivize participation and generate interest in their polls.<br><br>
Respondents who complete the poll questions or clicks on a button will be entered into a prize draw, with winners selected at random from the eligible entries. This poll type is an effective way to encourage participation and provide valuable insights.
`,
        comingSoon: true,
    },

    {
        id: 3,
        icon: "mdi-billboard",
        code: 'campaign',
        src: "/icons/3.png",
        color: "success",
        name: "Campaign",
        short_code: 'C',
        description: `The "campaign" poll type combines polls and surveys with advertising campaigns, allowing users to define a budget, target audience, channels, and duration. By enlisting influencers, users can reach a wider audience and gather valuable data while promoting a product or service.
          <br><br>
Overall, the "campaign" poll type is an effective way to conduct surveys and polls for marketing and advertising purposes, providing valuable data and engagement.`,
        comingSoon: false,
    },
    {
        id: 4,
        icon: "mdi-lightning-bolt-outline",
        code: 'versus',
        src: "/icons/4.png",
        color: "orange",
        name: "Versus",
        short_code: 'VS',
        description: `The "versus" poll type compares two options or items, allowing for quick and easy participation.<br><br> It's ideal for gathering feedback and opinions on a wide range of topics and provides an effective way for users to compare two items or concepts.`,
        comingSoon: false,
    },
]



export const DEFAULT_SIMPLE_POLL_PREVIEW = {
    name: "Favorite Pizza Topping",
    question: "When you order a pizza, which of the following toppings do you typically choose as your favorite?",
    terms_url: "https://pollyspot.com/terms-and-conditions.html",
    type: DEFAULT_POLL_TYPES[0],
    style: {
        header_font_color: "white",
        header_font_size: 32,
        header_bg_color_1: "",
        header_bg_color_2: "",
        logo_size: 60,
        logo_border_radius: 30,
        logo_border_color: '#ffffff',
        logo_border_width: 3,
        question_bg_color: "",
        question_font_color: "",
        question_font_size: 0,
        answer_bg_color: "",
        answer_font_color: "",
        answer_font_size: 0,
        answer_selected_color: "",
        answer_unselected_color: "",
        attachment_display: "separated",
        header: {
            file: {
                id: 1,
                url: '/examples/pizza-header.png'
            }

        },
        logo: {
            file: {
                id: 1,
                url: '/examples/pizza-logo.png'
            }

        },
        bg: {
            file: {
                id: 1,
                url: '/background-6.jpg'
            }
        },
    },
    settings: {
        allow_multiple_answers: true,
        block_display_before_start: false,
    },
    audience: {
        ages: [],
        genders: [],
        interests: [],
        sources: [],
        countries: [],
    },
    campaign: {
        budget: 100,
        goal: 100,
        dates: [
            moment().format("YYYY-MM-DD"),
            moment().add(2, "days").format("YYYY-MM-DD"),
        ],
    },
    attachments: [],
    answers: [{
        text: 'Extra PineApples!'
    }, {
        text: 'Pepperoni'
    }, {
        text: 'Mushrooms'

    }, {
        text: 'Onions'
    }, {
        text: 'Sausage'
    }, {
        text: 'Extra Cheese'
    }],
    files: [],
    logo: undefined,
    bg: undefined,

}


export const DEFAULT_VERSUS_POLL_PREVIEW = {
    name: "Favorite Pizza Topping",
    question: "When you order a pizza, which of the following toppings do you typically choose as your favorite?",
    terms_url: "https://pollyspot.com/terms-and-conditions.html",
    type: {},
    style: {
        header_font_color: "",
        header_font_size: 24,
        header_bg_color_1: "",
        header_bg_color_2: "",
        logo_width: 0,
        logo_border_radius: 0,
        question_bg_color: "",
        question_font_color: "",
        question_font_size: 0,
        answer_bg_color: "",
        answer_font_color: "",
        answer_font_size: 0,
        answer_selected_color: "",
        answer_unselected_color: "",
        attachment_display: "separated",
        logo: undefined,
        bg: undefined,
    },
    settings: {
        allow_multiple_answers: true,
        block_display_before_start: false,
    },
    audience: {
        ages: [],
        genders: [],
        interests: [],
        sources: [],
        countries: [],
    },
    campaign: {
        budget: 100,
        goal: 100,
        dates: [
            moment().format("YYYY-MM-DD"),
            moment().add(2, "days").format("YYYY-MM-DD"),
        ],
    },
    attachments: [],
    answers: [{
        text: 'Option #1'
    }, {
        text: 'Option #2'

    }],
    files: [],
    logo: undefined,
    bg: undefined,

}