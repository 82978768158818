<template>
  <v-carousel

    :cycle="cycle"
    :height="height"
    :show-arrows="value && value.length > 1"
    show-arrows-on-hover
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn color="primary" icon class="primary" v-bind="attrs" v-on="on"
        ><v-icon color="white">mdi-chevron-left</v-icon></v-btn
      >
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn color="primary" icon class="primary" v-bind="attrs" v-on="on"
        ><v-icon color="white">mdi-chevron-right</v-icon></v-btn
      >
    </template>
    <v-carousel-item v-for="(item, i) in value" :key="i">
      <v-sheet height="100%" tile>
        <PreviewElement
          :value="item"
          width="100%"
          :style="{
            height: 'calc(100% - 50px)',
            width:'100%'
          }"
        ></PreviewElement>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>
  
  
<script>
import PreviewElement from "./PreviewElement.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "380",
    },
    cycle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    PreviewElement,
  },
  methods: {},
};
</script>