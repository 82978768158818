<template>
  <iframe
    v-if="value.videoProvider === 'youtube'"
    :width="width"
    :height="height"
    title="YouTube video player"
    :src="value.video_src"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>

  <iframe
    v-else-if="value.videoProvider === 'vimeo'"
    :width="width"
    :height="height"
    :src="value.video_src"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen=""
  ></iframe>

  <video
    v-else-if="String(value.type).match(/video/)"
    :width="width"
    :height="height"
    class="center"
    controls
  >
    <source :src="getFileUrl(value.file)" id="video_here" />
    Your browser does not support HTML5 video.
  </video>
  <v-img
    v-else-if="String(value.type).match(/image/)"
    :width="width"
    :height="height"
    :src="getFileUrl(value.file)"
    :lazy-src="getFileUrl(value.file)"
  >
  </v-img>
</template>
    
    
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: "350",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {};
  },
  methods: {
    getFileUrl(file) {
      if (file.id && file.url) return file.url;
      else return URL.createObjectURL(file);
    },
  },
};
</script>


<style lang="scss" scoped>
.center {
 
}
</style>