<template>
  <v-app class="main-layout">
    <Navigation></Navigation>

    <!-- <Header></Header> -->

    <v-main class="main-content main-background">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="main-container">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- <Footer></Footer> -->
  </v-app>
</template>


<script>
// import Header from "../components/wad/molecules/layout/Header.vue";
// import Footer from "../components/wad/molecules/layout/Footer.vue";
import Navigation from "../components/wad/molecules/layout/Navigation.vue";

export default {
  data: () => ({
    dark: true,
    signInCompleted: false,

    selectedPortal: null,
    showSplash: false,
  }),
  mounted() {},
  components: {
    // Header,
    // Footer,
    Navigation,
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
.main-layout {
  height: 100%;

  .main-content {
    // max-width: calc(100% - 256px);
    // max-width: calc(100% - 56px);
    // background: linear-gradient(to right, #EBBF00, #686B6B);

    width: 100%;
    // height: calc(100% - 90px);
    // height: calc(100% - 64px);
    height: 100%;

    overflow: hidden;

    .main-container {
      max-width: 100%;
      // padding: 5%;
      // height: 100%;
      // overflow-y: scroll;
    }
  }
}
</style>