<template>
  <v-card class="preview-container secondary" :class="{ comingSoon }">
    <v-card
      class="preview-wrapper elevation-0"
      :style="{
        '--bgImage': `url(${
          value.style.bg ? getFileUrl(value.style.bg.file) : '/background-1.png'
        })`,
      }"
    >
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="comingSoon" class="coming-soon-text">
          <v-icon color="white" :size="34"> mdi-alert-outline </v-icon>

          Coming soon...</span
        >
      </v-slide-y-transition>

      <v-row class="d-flex justify-center ma-0 pa-0 preview-actions">
        <v-btn icon color="white" size="18" @click="openPreview">
          <v-icon size="18"> mdi-window-maximize </v-icon>
        </v-btn>
      </v-row>

      <SimplePollPreview
        v-if="
          !value.type ||
          (typeof value.type === 'object' && value.type.code === 'simple') ||
          (typeof value.type === 'string' && value.type === 'simple')
        "
        :value="value"
      ></SimplePollPreview>
    </v-card>

    <v-dialog
      v-model="fullScreenPreview"
      width="60%"
      height="80%"
      content-class="preview-dialog"
    >
      <v-card
        class="preview-wrapper-dialog"
        :style="{
          '--bgImage': `url(${
            value.style.bg
              ? getFileUrl(value.style.bg.file)
              : '/background-1.png'
          })`,
        }"
      >
        <SimplePollPreview
          v-if="
            !value.type ||
            (typeof value.type === 'object' && value.type.code === 'simple') ||
            (typeof value.type === 'string' && value.type === 'simple')
          "
          :value="value"
        ></SimplePollPreview>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
import SimplePollPreview from "./SimplePollPreview.vue";

// src="https://www.youtube.com/embed/zLaGZBPOEmo"
//
export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    comingSoon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SimplePollPreview,
  },
  data() {
    return {
      fullScreenPreview: false,
    };
  },
  methods: {
    openPreview() {
      this.fullScreenPreview = !this.fullScreenPreview;
    },
    getFileUrl(file) {
      if (file.id && file.url) return file.url;
      else return URL.createObjectURL(file);
    },
  },
};
</script>


<style lang="scss" scoped>
.preview-container {
  width: 100%;
  border-radius: 34px;
  height: 100%;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;

  &.comingSoon {
    &::after {
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 24px;
      backdrop-filter: blur(4px);
      z-index: 2;
    }
    .coming-soon-text {
      text-align: center;
      color: white;
      padding: 10px;
      position: absolute;
      z-index: 3;
      font-size: 40px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      white-space: nowrap;
      border: 1px solid;
      width: 200px;
      // background: rgba(0, 0, 0, 0.5);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
    }
  }

  .preview-wrapper {
    overflow-y: auto;
    border-radius: 26px !important;
    height: calc(100% - 16px);
    margin: 8px;
    width: 100%;

    // background: url("/background-1.png");
    background: var(--bgImage);
    background-size: cover;

    .preview-actions {
      position: absolute;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}

.preview-wrapper-dialog {
  width: 100%;
  height: 100%;
  background: var(--bgImage);
  background-size: cover;
}
</style>

