var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper-dialog",style:({
    '--bgImage': `url(${
      _vm.publicPoll.style.bg
        ? _vm.getFileUrl(_vm.publicPoll.style.bg.file)
        : '/background-1.png'
    })`,
    'background-position': `${_vm.x}px ${_vm.y}px`,
  }),on:{"mousemove":_vm.mouseMove}},[_c('SimplePollPreview',{attrs:{"value":_vm.publicPoll,"submit":""},on:{"onSubmit":_vm.onSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }