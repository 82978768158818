var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-0 page-card"},[_c('v-card-title',{staticClass:"page-card-title"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":() => (_vm.newPollDialog = !_vm.newPollDialog)}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('h2',{staticClass:"page-title"},[_vm._v("My Polls")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"page-content"},[_c('v-row',_vm._l((_vm.polls),function(poll){return _c('v-col',{key:poll.id,attrs:{"cols":"12","lg":"6"}},[_c('PollListItem',{attrs:{"value":poll},on:{"onDetails":_vm.onDetails,"onShare":_vm.onShare}})],1)}),1)],1),_c('v-dialog',{attrs:{"content-class":'type-selection-dialog content-card primary '},model:{value:(_vm.newPollDialog),callback:function ($$v) {_vm.newPollDialog=$$v},expression:"newPollDialog"}},[_c('v-card',{staticClass:"pa-4 bg-grey-gradient"},[_c('v-row',[_c('v-col',{staticClass:"types-col"},[_c('v-row',[_c('v-col',_vm._l((_vm.types),function(type,i){return _c('v-card',{key:type.id,staticClass:"option-poll",class:{
                  active: _vm.active.id === type.id,
                  comingSoon: type.comingSoon,
                },attrs:{"id":'poll-type-' + type.id},on:{"click":() => _vm.onCreate(type),"mouseover":() => {
                    _vm.active = type;
                    _vm.activeI = i;
                  }}},[_c('v-img',{staticClass:"background-icon",attrs:{"src":type.src,"lazy-src":type.src}}),_c('span',{staticClass:"type-name"},[_vm._v(" "+_vm._s(type.short_code))])],1)}),1)],1)],1),_c('v-col',{staticClass:"description-col"},[_c('v-slide-y-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(_vm.active && _vm.active.name)?_c('h1',{key:'title' + _vm.active.name},[_vm._v(" "+_vm._s(_vm.active ? _vm.active.name : "")+" ")]):_vm._e()]),_c('v-slide-y-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(_vm.active && _vm.active.name)?_c('p',{key:'content' + _vm.active.name,staticClass:"type-description",domProps:{"innerHTML":_vm._s(_vm.active.description)}}):_vm._e()])],1),_c('v-col',{staticClass:"preview-col"},[_c('MobilePollPreview',{attrs:{"coming-soon":_vm.active.comingSoon,"value":_vm.previews[_vm.activeI]}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }