<template>
  <v-card class="elevation-0 page-card">
    <v-card-title class="page-card-title">
      <v-btn
        icon
        class="mr-2"
        color="primary"
        @click="() => (newPollDialog = !newPollDialog)"
        ><v-icon>mdi-plus</v-icon>
      </v-btn>
      <h2 class="page-title">My Polls</h2>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="page-content">
      <v-row>
        <v-col cols="12" lg="6" v-for="poll in polls" :key="poll.id">
          <PollListItem
            :value="poll"
            @onDetails="onDetails"
            @onShare="onShare"
          ></PollListItem>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="newPollDialog"
      :content-class="'type-selection-dialog content-card primary '"
    >
      <v-card class="pa-4 bg-grey-gradient">
        <v-row>
          <v-col class="types-col">
            <v-row>
              <v-col>
                <v-card
                  class="option-poll"
                  v-for="(type, i) in types"
                  :key="type.id"
                  @click="() => onCreate(type)"
                  :class="{
                    active: active.id === type.id,
                    comingSoon: type.comingSoon,
                  }"
                  :id="'poll-type-' + type.id"
                  @mouseover="
                    () => {
                      active = type;
                      activeI = i;
                    }
                  "
                >
                  <v-img
                    class="background-icon"
                    :src="type.src"
                    :lazy-src="type.src"
                  ></v-img>

                  <span class="type-name"> {{ type.short_code }}</span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="description-col">
            <v-slide-y-transition leave-absolute hide-on-leave>
              <h1 v-if="active && active.name" :key="'title' + active.name">
                {{ active ? active.name : "" }}
              </h1>
            </v-slide-y-transition>

            <v-slide-y-transition leave-absolute hide-on-leave>
              <p
                v-if="active && active.name"
                v-html="active.description"
                :key="'content' + active.name"
                class="type-description"
              ></p>
            </v-slide-y-transition>
          </v-col>
          <v-col class="preview-col">
            <MobilePollPreview
              :coming-soon="active.comingSoon"
              :value="previews[activeI]"
            ></MobilePollPreview>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import PollListItem from "../components/wad/molecules/poll/PollListItem.vue";
import MobilePollPreview from "../components/wad/organisms/poll/MobilePollPreview.vue";
import {
  DEFAULT_POLL_TYPES,
  DEFAULT_SIMPLE_POLL_PREVIEW,
  DEFAULT_VERSUS_POLL_PREVIEW,
} from "../constants/poll.constants";

export default {
  data() {
    const types = DEFAULT_POLL_TYPES;

    return {
      newPollDialog: false,
      previews: [
        DEFAULT_SIMPLE_POLL_PREVIEW,
        DEFAULT_SIMPLE_POLL_PREVIEW,
        DEFAULT_SIMPLE_POLL_PREVIEW,
        DEFAULT_VERSUS_POLL_PREVIEW,
      ],

      active: types[0],
      activeI: 0,
      types,
    };
  },
  computed: {
    ...mapState("PollStore", ["polls"]),
  },
  components: {
    PollListItem,
    MobilePollPreview,
  },
  created() {
    this.$store.dispatch("PollStore/getList");
  },
  methods: {
    onShare(poll) {
      this.$store.dispatch("PollStore/getPersonalPollLink", { poll });
    },
    onDetails(poll) {
      this.$router.push(`/my-polls/${poll.id}`, {
        params: { id: poll.id },
      });
    },
    onCreate(type) {
      this.$router.push(`/new-poll/${type.id}`);
    },
    add3dEffect(id) {
      /* Store the element in el */
      let el = document.getElementById(id);

      /* Get the height and width of the element */
      const height = el.clientHeight;
      const width = el.clientWidth;

      /*
       * Add a listener for mousemove event
       * Which will trigger function 'handleMove'
       * On mousemove
       */
      el.addEventListener("mousemove", handleMove);

      /* Define function a */
      function handleMove(e) {
        /*
         * Get position of mouse cursor
         * With respect to the element
         * On mouseover
         */
        /* Store the x position */
        const xVal = e.layerX;
        /* Store the y position */
        const yVal = e.layerY;

        /*
         * Calculate rotation valuee along the Y-axis
         * Here the multiplier 20 is to
         * Control the rotation
         * You can change the value and see the results
         */
        const yRotation = 40 * ((xVal - width / 2) / width);

        /* Calculate the rotation along the X-axis */
        const xRotation = -40 * ((yVal - height / 2) / height);

        /* Generate string for CSS transform property */
        const string =
          "perspective(500px) scale(1.1) rotateX(" +
          xRotation +
          "deg) rotateY(" +
          yRotation +
          "deg)";

        /* Apply the calculated transformation */
        el.style.transform = string;
      }

      /* Add listener for mouseout event, remove the rotation */
      el.addEventListener("mouseout", function () {
        el.style.transform =
          "perspective(500px) scale(1) rotateX(0) rotateY(0)";
      });

      /* Add listener for mousedown event, to simulate click */
      el.addEventListener("mousedown", function () {
        el.style.transform =
          "perspective(500px) scale(0.9) rotateX(0) rotateY(0)";
      });

      /* Add listener for mouseup, simulate release of mouse click */
      el.addEventListener("mouseup", function () {
        el.style.transform =
          "perspective(500px) scale(1.1) rotateX(0) rotateY(0)";
      });
    },
  },

  watch: {
    newPollDialog(newVal) {
      if (newVal) {
        setTimeout(() => {
          for (const type of this.types) {
            //add effect
            this.add3dEffect("poll-type-" + type.id);
          }
        }, 100);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.types-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 140px;
  max-width: 200px;
  flex-grow: 1;
  flex-shrink: 1;
}

.description-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  color: white;

  text-align: center;

  .type-description {
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 24px;
    padding: 20px;
  }
}

.preview-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 350px;
  flex-grow: 1;
  flex-shrink: 1;
}

.option-poll {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 120px;
  width: 120px;
  position: relative;

  margin: 20px;
  overflow: hidden;
  padding: 2px;

  // &:hover {
  // }

  .background-icon {
    // position: absolute;
    // border-radius: 34px;
    border-radius: 24px;
  }

  &.comingSoon {
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0.8;
      left: 0;
    }
  }

  .type-name {
    font-size: 30px;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid white;
    border-radius: 20px;

    padding-bottom: 0;
    z-index: 1;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;

    &::before {
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      border-radius: 20px;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #0000ff;
      opacity: 0.4;
      left: 0;
    }
  }

  display: block;
  transition: box-shadow 0.1s, transform 0.1s;

  &:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
}

.active {
  position: relative;
  z-index: 0;
}

.active:before,
.active:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000
  );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.active:after {
  filter: blur(50px);
}
</style>