<template>
  <v-card class="elevation-0 transparent details-page">
    <v-card-title
      v-if="poll.id"
      class="page-card-title poll-details-page-title"
    >
      <v-btn icon @click="$router.push('/my-polls')"
        ><v-icon color="primary" class="mr-2">mdi-arrow-left</v-icon></v-btn
      >

      <h2 class="page-title">#{{ poll.id }} {{ poll.name }}</h2>

      <v-btn icon @click="onShare" color="primary" class="ml-2">
        <v-icon class="mr-2">mdi-share-variant</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text class="page-content">
      <v-row class="pa-0 ma-0 main-row">
        <v-col  class="pa-0 ma-0 content-col">
          <div class="content">
            <v-tabs
              v-model="tab"
              centered
              dark
              class="content-card black tabs-header"
              background-color="transparent"
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#answers-tab">
                Answers
                <v-icon>mdi-poll</v-icon>
              </v-tab>

              <v-tab href="#devices-tab">
                Devices
                <v-icon>mdi-cellphone-link</v-icon>
              </v-tab>

              <v-tab href="#locations-tab">
                Location
                <v-icon>mdi-map-marker</v-icon>
              </v-tab>

              <v-tab href="#sources-tab">
                Sources
                <v-icon>mdi-source-branch</v-icon>
              </v-tab>

              <v-tab href="#settings-tab">
                Settings
                <v-icon>mdi-cog</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="transparent">
              <v-tab-item :value="'answers-tab'" class="tab-item">
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" xl="6" md="5" sm="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> Answers</v-card-title>
                      <PollResults
                        :id="'answers'"
                        :value="analytics.answers"
                      ></PollResults>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xl="6" md="7" sm="12">
                    <v-card class="content-card white-blur">
                      <v-card-title>
                        Daily Answers [{{ formatDates(poll.campaign.start) }}-
                        {{ formatDates(poll.campaign.end) }}]</v-card-title
                      >
                      <PieCalendar
                        :value="analytics.answersByDay"
                        :start="poll.campaign.start"
                        :end="poll.campaign.end"
                      ></PieCalendar>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="12" md="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> Clicks Hourly (48h)</v-card-title>
                      <LineChartVue
                        :id="'click-by-hour'"
                        :value="analytics.clicksByDay"
                      ></LineChartVue>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'devices-tab'" class="tab-item">
                <v-row class="pa-0 ma-0">
                  <v-col cols="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> Browsers</v-card-title>
                      <PieChartVue
                        :height="250"
                        :id="'browsers'"
                        :value="analytics.browsers"
                      ></PieChartVue>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> Devices</v-card-title>
                      <PieChartVue
                        :height="250"
                        :id="'devices'"
                        :value="analytics.devices"
                      ></PieChartVue>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> OS</v-card-title>
                      <PieChartVue
                        :height="250"
                        :id="'os'"
                        :value="analytics.os"
                      ></PieChartVue>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card class="content-card white-blur">
                      <v-card-title> Answers By Device</v-card-title>
                      <AnswerBySource
                        :id="'device-answers'"
                        :value="analytics.answersByDevice"
                      ></AnswerBySource>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card class="content-card white-blur">
                      <v-card-title>Answers By Browser</v-card-title>
                      <AnswerBySource
                        :id="'browser-answers'"
                        :value="analytics.answersByBrowser"
                      ></AnswerBySource>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'locations-tab'" class="tab-item">
                <v-row class="pa-0 ma-0">
                  <v-alert
                    icon="mdi-shield-lock-outline"
                    class="coming-soon"
                    prominent
                    text
                    type="info"
                  >
                    Coming soon...
                  </v-alert>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'sources-tab'" class="tab-item">
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" lg="12" md="12">
                    <v-card class="mx-auto">
                      <v-list>
                        <v-subheader>Default</v-subheader>

                        <v-list-item
                          link
                          v-for="token in poll.tokens.filter((t) => !t.source)"
                          :key="token.id"
                        >
                          <v-list-item-avatar>
                            <v-icon>mdi-alpha-d-circle-outline </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              >Your personal Default Link</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="() => copySourceLink(token)">
                              <v-icon color="grey lighten-1"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>

                        <v-subheader>Socials</v-subheader>

                        <v-list-item
                          link
                          v-for="token in poll.tokens.filter((t) => t.source)"
                          :key="token.id"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              v-if="token.source.icon"
                              :color="token.source.color || 'primary'"
                              >{{ token.source.icon }}
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              token.source.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="() => copySourceLink(token)">
                              <v-icon color="grey lighten-1"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" lg="6" md="6">
                    <v-card>
                      <PerDayChart></PerDayChart>
                    </v-card>
                  </v-col> -->

                  <!-- <v-alert
                    icon="mdi-shield-lock-outline"
                    class="coming-soon"
                    prominent
                    text
                    type="info"
                  >
                    Coming soon...
                  </v-alert> -->
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'settings-tab'" class="tab-item">
                <v-row class="pa-0 ma-0">
                  <v-alert
                    class="coming-soon"
                    icon="mdi-shield-lock-outline"
                    prominent
                    text
                    type="info"
                  >
                    Coming soon...
                  </v-alert>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 preview-col">
          <v-card class="content-card primary fraud-score">
            <FraudScore
              :value="analytics.avgFraudScore"
              class="mb-4"
            ></FraudScore>
          </v-card>

          <MobilePollPreview :value="poll"></MobilePollPreview>

          <v-card class="content-card primary total-score">
            <h2>Total Answers: {{ totalAnswers }}</h2>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
// import moment from "moment";
import LineChartVue from "../components/wad/molecules/dashboard/LineChart.vue";
// import PerDayChart from "../components/wad/molecules/dashboard/PerDayChart.vue";
import PieChartVue from "../components/wad/molecules/dashboard/PieChart.vue";

import { mapState } from "vuex";
import MobilePollPreview from "../components/wad/organisms/poll/MobilePollPreview.vue";
import FraudScore from "../components/wad/molecules/dashboard/FraudScore.vue";
import PieCalendar from "../components/wad/molecules/dashboard/PieCalendar.vue";
import moment from "moment";
import PollResults from "../components/wad/molecules/dashboard/PollResults.vue";
import AnswerBySource from "../components/wad/molecules/dashboard/AnswerBySource.vue";

export default {
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    ...mapState("PollStore", ["poll", "analytics"]),
    totalAnswers: function () {
      return this.analytics.answers
        ? Object.keys(this.analytics.answers).reduce(
            (acc, curr) => acc + parseInt(this.analytics.answers[curr]),
            0
          )
        : 0;
    },
  },
  components: {
    MobilePollPreview,
    // PerDayChart,
    LineChartVue,
    PieChartVue,
    FraudScore,
    PieCalendar,
    PollResults,
    AnswerBySource,
  },
  created() {
    this.$store.dispatch("PollStore/get", { id: this.$route.params.id });
    this.$store.dispatch("PollStore/analytics", { id: this.$route.params.id });
  },
  methods: {
    formatDates(date) {
      return moment(date).format("MMMM Do");
    },
    onShare() {
      this.$store.dispatch("PollStore/getPersonalPollLink", {
        poll: this.poll,
      });
    },
    async copySourceLink(token) {
      await navigator.clipboard.writeText(
        `${window.location.origin}/public/poll?token=${token.short_token}`
      );

      this.$toast.open({
        type: "success",
        message: "Poll Link Copied",
        position: "top-right",
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.preview-col {
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
  max-width: 360px;
  flex-shrink: 1;
  flex-grow: 1;
}

.poll-details-page-title {
  margin-left: 40px;
  @media (max-width: 864px) {
    margin-left: 0px;
  }
}
.details-page {
}

.main-row {
  display: flex;
  @media (max-width: 864px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    display: flex;
    flex-direction: column-reverse;
  }
}

.content-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 99;

  .content {
    overflow-y: scroll;
    height: calc(100vh - 125px);

    &::-webkit-scrollbar {
      width: 0px;
    }

    .tabs-header {
      height: 100px !important;
      display: flex;
      flex-direction: row;
      border-radius: 34px;
      justify-content: center;
      align-items: center;
      margin-left: 40px;
      margin-right: 40px;

      position: absolute;
      width: calc(100% - 440px);
      z-index: 2;

      @media (max-width: 864px) {
        margin-left: 0px;
        width: 100%;
        margin-top: 10px;
        left: 0;
      }
    }
    .tab-item {
      min-height: calc(100vh - 200px);

      @media (max-width: 864px) {
        width: 100%;
        padding-top: 00px;
        padding-left: 0px;
        left: 0;
      }

      // overflow-y: auto;
      // height: calc(100vh - 225px);sc
      background: transparent;
      padding-left: 36px;
      padding-bottom: 40px;
      // padding-ri: 40px;
      margin-top: 110px;
    }
  }
}

.fraud-score {
  height: 100px;
  max-width: 324px;
  margin-bottom: 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-score {
  height: 62px;
  max-width: 324px;
  margin-top: 20px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300 !important;

  h2 {
    font-weight: 300;
  }
}

.coming-soon {
  width: 100%;
  margin: 30px;
  font-size: 24px;
  .v-alert__content {
    color: white !important;
  }
}
</style>