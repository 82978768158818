<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <SelectorSourceCategories
          v-if="hideBudget"
          autoload
          multiple
          class="mt-4"
          label="Target Sources"
          v-model="sources"
          :helpText="'You can specify a set of sources from where you want to receive answers'"
          required
          return-object
        ></SelectorSourceCategories>

        <HelpFormInputVue
          v-if="!hideBudget"
          v-model="form.campaign.budget"
          :rules="budgetRules"
          :icon="'mdi-currency-usd'"
          type="number"
          label="Poll Budget"
          :helpText="'Please provide a budget for the whole duration of the poll.'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          v-if="!hideBudget"
          v-model="form.campaign.goal"
          :rules="goalRules"
          :icon="'mdi-account-multiple'"
          type="number"
          label="Desired Number of Answers (Goal)"
          :helpText="'Please provide a number of answers you wish to receive.'"
          required
        ></HelpFormInputVue>

        <span class="primary--text approx-price">
          Approx PPA : ~
          {{
            !form.campaign.budget || !form.campaign.goal
              ? "-"
              : "$" +
                priceFormatter(form.campaign.budget / form.campaign.goal, 4)
          }}
        </span>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" class="duration-col">
        <!-- <h2>Duration</h2> -->
        <v-date-picker
          full-width
          color="primary"
          v-model="form.campaign.dates"
          :min="new Date().toISOString()"
          range
        ></v-date-picker>
      </v-col>
    </v-row>
  </v-form>
</template>
          
          
    <script>
import { FormatterHelper } from "../../../helpers/formatter.helper";
//   import SelectorDefaultVue from "../../atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import SelectorSourceCategories from "../../atoms/common/autocompletes/SelectorSourceCategories.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    hideBudget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // formatters
      priceFormatter: FormatterHelper.priceCustom,
      // Form
      pValid: this.valid,
      form: this.value,
      newAnswer: "",
      newVideo: "",
      sources: [],

      // ======rules
      budgetRules: [
        (v) => !!v || "Budget is required",
        (v) => (isNaN(parseInt(v)) ? "Budget should be a number" : true),
        (v) => (v < 0 ? "Budget should be higher then 0" : true),
      ],
      goalRules: [
        (v) => !!v || "Goal is required",
        (v) => (isNaN(parseInt(v)) ? "Goal should be a number" : true),
        (v) => (v < 0 ? "Goal should be higher then 0" : true),
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {},
  components: {
    HelpFormInputVue,
    SelectorSourceCategories,
    //   SelectorDefaultVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    sources: {
      handler(newVal) {
        this.form.audience.sources = newVal.map((category) => ({ category }));
      },
      deep: true,
    },
  },
};
</script>
          
          
      <style lang="scss" scoped>
.answers-container {
  transition: all 0.5s ease;
}
.answer-item {
  border-radius: 14px;
  border-color: #ebbf00;

  border-bottom: 1px solid;

  &:hover::before {
    border-radius: 14px !important;
  }
}

.approx-price {
  font-size: 24px;
}

.duration-col {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>