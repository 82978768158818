var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"preview-container secondary",class:{ comingSoon: _vm.comingSoon }},[_c('v-card',{staticClass:"preview-wrapper elevation-0",style:({
      '--bgImage': `url(${
        _vm.value.style.bg ? _vm.getFileUrl(_vm.value.style.bg.file) : '/background-1.png'
      })`,
    })},[_c('v-slide-y-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(_vm.comingSoon)?_c('span',{staticClass:"coming-soon-text"},[_c('v-icon',{attrs:{"color":"white","size":34}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" Coming soon...")],1):_vm._e()]),_c('v-row',{staticClass:"d-flex justify-center ma-0 pa-0 preview-actions"},[_c('v-btn',{attrs:{"icon":"","color":"white","size":"18"},on:{"click":_vm.openPreview}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-window-maximize ")])],1)],1),(
        !_vm.value.type ||
        (typeof _vm.value.type === 'object' && _vm.value.type.code === 'simple') ||
        (typeof _vm.value.type === 'string' && _vm.value.type === 'simple')
      )?_c('SimplePollPreview',{attrs:{"value":_vm.value}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"60%","height":"80%","content-class":"preview-dialog"},model:{value:(_vm.fullScreenPreview),callback:function ($$v) {_vm.fullScreenPreview=$$v},expression:"fullScreenPreview"}},[_c('v-card',{staticClass:"preview-wrapper-dialog",style:({
        '--bgImage': `url(${
          _vm.value.style.bg
            ? _vm.getFileUrl(_vm.value.style.bg.file)
            : '/background-1.png'
        })`,
      })},[(
          !_vm.value.type ||
          (typeof _vm.value.type === 'object' && _vm.value.type.code === 'simple') ||
          (typeof _vm.value.type === 'string' && _vm.value.type === 'simple')
        )?_c('SimplePollPreview',{attrs:{"value":_vm.value}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }