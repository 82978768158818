<template>
  <v-card outlined class="mx-auto ma-2 poll-item content-card white-blur">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          {{ value.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ value.question }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div
      class="status"
      v-if="value.status"
      :class="{
        primary: value.status.code === 'COMPLETED',
        'blue-grey lighten-2': value.status.code === 'PENDING',
        error: value.status.code === 'TERMINATED',
        'light-green lighten-2': value.status.code === 'RUNNING',
      }"
    >
      <span class="mt-1">
        {{ value.status.name }}
      </span>
    </div>

    <v-row class="ml-4">
      <v-chip>
        {{ formatDates(value.campaign.start) }} -
        {{ formatDates(value.campaign.end) }}
      </v-chip>
    </v-row>

    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-col cols="6">
          <v-rating
            :value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>

          <div class="grey--text ms-4">4.5 (413)</div>
        </v-col>
      </v-row>
    </v-card-text>
    <MobilePollPreviewVue
      class="scaled-preview"
      :value="value"
    ></MobilePollPreviewVue>

    <v-card-actions class="card-actions-bottom">
      <v-btn outlined rounded text @click="() => $emit('onDetails', value)">
        View Details
      </v-btn>
      <v-btn outlined rounded text @click="() => $emit('onShare', value)">
        <v-icon :size="14" class="mr-2">mdi-share-variant</v-icon> Share</v-btn
      >
    </v-card-actions>
  </v-card>
</template>


<script>
import moment from "moment";
import MobilePollPreviewVue from "../../organisms/poll/MobilePollPreview.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MobilePollPreviewVue,
  },
  methods: {
    formatDates(date) {
      return moment(date).format("MMMM Do");
    },
  },
};
</script>


<style lang="scss" scoped>
.poll-item {
  overflow: hidden;
  height: 300px;
  background-color: #c4a011;
  .status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    top: 22px;
  }

  .scaled-preview {
    right: 0;
    transform: scale(0.5);
    display: flex;
    position: absolute;

    @media (max-width: 864px) {
      // margin-left: 0px;
      // margin-right: 0px;
      // padding-left: 0px !important;
      // padding-right: 0px !important;
      transform: scale(0.3);
      top: -105px;
      // width: 140px;
      right: -76px;
    }

    /* top: 0; */
    /* left: 0; */
    /* height: 100vh; */
    /* width: 100vw; */
    /* transform: translate(10px, 10px); */
    /* right: 200px; */
    /* left: 444px; */
    height: 500px;
    /* bottom: 230px; */
    top: -105px;
    width: 340px;
  }
}
</style>