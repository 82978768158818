
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-card-title class="page-card-title">
      <!-- <v-btn
          icon
          class="mr-2"
          color="primary"
          @click="() => (newPollDialog = !newPollDialog)"
          ><v-icon>mdi-plus</v-icon>
        </v-btn> -->
      <h2 class="page-title">My Influencers</h2>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row class="pa-0 ma-0">
        <v-alert
          icon="mdi-shield-lock-outline"
          class="coming-soon"
          prominent
          text
          type="info"
        >
          Coming soon...
        </v-alert>
      </v-row>
    </v-card-text>
  </v-card>
</template>
    
    <script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>


<style lang="scss" scoped>
.coming-soon {
  width: 100%;
  margin: 30px;
  font-size: 24px;
  .v-alert__content {
    color: white !important;
  }
}
</style>